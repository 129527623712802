import {combineReducers} from "redux";
import uiReducer from "./reducers/uiReducer";
import authReducer from '../../views/Authentication/Login/store/reducer/authenticationReducer';
import cartReducer from '../../views/Dashboard/DashboardCart/store/reducer/cartReducer';

const allReducers = combineReducers({
    ui: uiReducer,
    authentication: authReducer,
    cart: cartReducer,
});

export default allReducers;

