import * as actionTypes from './actionTypes';
import {uiStopLoading, uiStartLoading} from '../../../../../configuration/redux/actions/ui';
import urls from "../../../../../constants/urls";
import AsyncStorageHelper from "../../../../../helpers/AsyncStorageHelper";
import asyncStorageKeys from "../../../../../constants/asyncStorageKeys";
import UrlRequest from "../../../../../helpers/UrlRequestHelper";
import {setTotalBadge, setCartId} from "../../../../Dashboard/DashboardCart/store/actions/cart";

const loginAction = () => {
    return {
        type: actionTypes.LOGIN
    };
};

const loginSuccessAction = (email, id, access, refresh, permissions, history) => {
    //Save token to storage
    new AsyncStorageHelper().setItem(asyncStorageKeys.tokens, {
        access: access,
        refresh: refresh,
    });

    let panel_access = false;
    if (permissions.includes("panel.view_panel")) {
        panel_access = true;
        setTimeout(() => {
            history.push("/dashboard");
        }, 1);
        console.log("pushed to sh");
    } else {
        setTimeout(() => {
            history.push("/shop");
        }, 1);
    }

    return {
        type: actionTypes.LOGIN_SUCCESS,
        email: email,
        id: id,
        access: access,
        refresh: refresh,
        permissions: permissions,
        panel_access: panel_access,
    };
};

const rememberMeAction = (rememberMe) => {
    //Save token to storage
    if (rememberMe)
        new AsyncStorageHelper().setItem(asyncStorageKeys.rememberMe, {
            rememberMe: rememberMe
        });

    return {
        type: actionTypes.REMEMBER_ME,
    };
};


export const loginUser = (response, rememberMe, history) => {
    return (dispatch) => {
        dispatch(uiStartLoading());
        dispatch(loginAction());
        dispatch(rememberMeAction(rememberMe));
        dispatch(setTotalBadge(response.extra.cart_badge));
        dispatch(setCartId(response.extra.cart_id));
        dispatch(loginSuccessAction(response.user.email, response.user.id, response.tokens.access, response.tokens.refresh, response.user.permissions, history));
        dispatch(uiStopLoading());
    };
};

const refreshTokenAction = () => {
    return {
        type: actionTypes.REFRESH_TOKEN
    };
};

const refreshTokenFailAction = () => {
    return {
        type: actionTypes.REFRESH_TOKEN_FAIL
    };
};

const setAuthenticatedToTrue = () => {
    return {
        type: actionTypes.SET_AUTH_TRUE
    };
};

const autoRefreshTokenSuccessAction = (email, access, refresh, id, permissions, history) => {
    let panel_access = false;
    if (permissions.includes("panel.view_panel")) {
        panel_access = true;
        setTimeout(() => {
            history.push("/dashboard");
        }, 1);
    }

    return {
        type: actionTypes.REFRESH_TOKEN_SUCCESS,
        email: email,
        id: id,
        access: access,
        refresh: refresh,
        permissions: permissions,
        panel_access: panel_access,
    };


};

export const autoAuthenticate = (access, refresh, history) => {
    return async (dispatch, done) => {
        dispatch(uiStartLoading());
        dispatch(refreshTokenAction());
        new UrlRequest(urls.VERIFY_TOKEN).postMethod({token: access},
            (response) => {
                // let resp = response.data;
                dispatch(autoRefreshTokenSuccessAction(response.email, access, refresh, response.id, response.permissions, history));
                dispatch(setTotalBadge(response.extra.cart_badge));
                dispatch(setCartId(response.extra.cart_id));
                dispatch(setAuthenticatedToTrue());
            }, () => {
                history.push("/login");
                //Remove all Async storage items and re authenticate
                new AsyncStorageHelper().removeItem(asyncStorageKeys.tokens);
                dispatch(refreshTokenFailAction());
            }
        );
    };
};

export const resetAuth = () => {
    return {
        type: actionTypes.RESET_AUTH
    };
};

export const updateTokens = (tokens) => {
    return {
        type: actionTypes.SET_UPDATED_TOKENS,
        access: tokens.access,
        refresh: tokens.refresh
    };
};

