import React, {lazy, Suspense} from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {Redirect, Route, Switch} from "react-router-dom";

import {connect} from "react-redux";
import {withRouter} from "react-router";
import rootRoutes from "../rootRoutes";
import RootNavBar from "../components/Navbars/RootNavBar";
// import {CSSTransition, TransitionGroup, SwitchTransition} from 'react-transition-group';
import './Root.scss';
import Footer from "../components/Footer/Footer";
import CustomLoader from "../components/CustomLoader/CustomLoader";
import RootHeader from "../components/Header/RootHeader";
const NotFound404 = lazy(() => import('../views/ErrorView/404.jsx'));

let ps;


class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "black",
            activeColor: "success"
        };
        this.mainPanel = React.createRef();
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            this.mainPanel.current.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }

    render() {
        return (
            <div className="wrapper">
                <RootHeader {...this.props}/>
                <div className="main-panel-root" ref={this.mainPanel}>
                    <RootNavBar {...this.props} routes={rootRoutes}/>
                    <Suspense fallback={<CustomLoader/>}>
                        <Switch>
                            {rootRoutes.map((prop, key) => {
                                return (
                                    <Route
                                        exact
                                        path={prop.layout + prop.path}
                                        component={() => <prop.component
                                            {...this.props}/>
                                        }
                                        key={key}
                                    />
                                );
                            })}
                            <Redirect component={NotFound404} to={"/404"}/>
                        </Switch>
                    </Suspense>
                    <Footer fluid/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated
    };
};

export default connect(mapStateToProps, null)(withRouter(Root));
