
// const baseUrl = 'http://localhost:8000/api/v1/';
const baseUrl = 'https://backend.bioactiveherb.gr/api/v1/';

export default {
    BASE_URL: baseUrl,
    //Tokens
    LOGIN: baseUrl + "auth/login/",
    SIGNUP: baseUrl + "auth/signup/",
    REFRESH_TOKEN: baseUrl + "auth/token/refresh/",
    VERIFY_TOKEN: baseUrl + "auth/token/verify/",
    //Dashboard
    USER_RETRIEVE_DASHBOARD_INFORMATION: baseUrl + "user/dashboard/", // needs the id of the user
    //User
    USER_PROFILE_RETRIEVE_INFORMATION: baseUrl + "user/info/", // Needs the id of the user
    USER_UPDATE_INFORMATION: baseUrl + "user/update/", // Needs the id of the user
    USER_UPDATE_PROFILE: baseUrl + "user/profile/update/user/", // Needs the id of the user
    USER_UPDATE_ADDRESS: baseUrl + "user/address/update/user/", // Needs the id of the user
    USER_UPDATE_SETTINGS: baseUrl + "user/settings/update/", // Needs the id of the user
    //Blog
    BLOG_LIST: baseUrl + "blog/",
    BLOG_LIST_BY_USER: baseUrl + "blog/user/", // Needs the pk of the user (needs token)
    BLOG_CREATE: baseUrl + "blog/create/", // Needs the pk of the user (needs token)
    BLOG_RETRIEVE: baseUrl + "blog/", // Needs the pk of the blog
    BLOG_DELETE: baseUrl + "blog/delete/", // Needs the pk of the blog
    BLOG_UPDATE: baseUrl + "blog/update/", // Needs the pk of the blog
    //Herbs
    HERBS_LIST: baseUrl + "herb/",
    HERBS_LIST_BY_USER: baseUrl + "herb/user/", // Needs the pk of the user
    HERB_CREATE: baseUrl + "herb/create/",
    HERB_UPDATE: baseUrl + "herb/update/", // Needs the pk of the herb
    HERB_RETRIEVE: baseUrl + "herb/", // Needs the pk of the herb
    HERB_DELETE: baseUrl + "herb/delete/", // Needs the pk of the herb
    HERB_CREATE_IMAGE: baseUrl + "herb/image/create/",
    HERB_UPDATE_IMAGE: baseUrl + "herb/image/update/", // Needs the pk of the image
    HERB_DELETE_IMAGE: baseUrl + "herb/image/delete/", // Needs the pk of the image
    HERB_CATEGORY_CREATE: baseUrl + "herb/herb-category/create/",
    HERB_CATEGORY_LIST: baseUrl + "herb/herb-category/",
    HERB_CATEGORIES_TAGS_LIST: baseUrl + "herb/herb-categories-tags/",
    //Products
    PRODUCT_LIST: baseUrl + "product/",
    PRODUCT_LIST_BY_USER: baseUrl + "product/user/", // Needs the pk of the user
    PRODUCT_CREATE: baseUrl + "product/create/",
    PRODUCT_UPDATE: baseUrl + "product/update/", // Needs the pk of the herb
    PRODUCT_RETRIEVE: baseUrl + "product/", // Needs the pk of the herb
    PRODUCT_DELETE: baseUrl + "product/delete/", // Needs the pk of the herb
    PRODUCT_CREATE_IMAGE: baseUrl + "product/image/create/",
    PRODUCT_UPDATE_IMAGE: baseUrl + "product/image/update/", // Needs the pk of the image
    PRODUCT_DELETE_IMAGE: baseUrl + "product/image/delete/", // Needs the pk of the image
    PRODUCT_CATEGORY_CREATE: baseUrl + "product/product-category/create/",
    PRODUCT_CATEGORY_LIST: baseUrl + "product/product-category/",
    PRODUCT_CATEGORIES_TAGS_LIST: baseUrl + "product/product-categories-tags/",
    //Final products
    FINAL_PRODUCT_LIST: baseUrl + "f-product/",
    FINAL_PRODUCT_LIST_BY_USER: baseUrl + "f-product/user/", // Needs the pk of the user
    FINAL_PRODUCT_CREATE: baseUrl + "f-product/create/",
    FINAL_PRODUCT_UPDATE: baseUrl + "f-product/update/", // Needs the pk of the f product
    FINAL_PRODUCT_RETRIEVE: baseUrl + "f-product/", // Needs the pk of the f product
    FINAL_PRODUCT_RETRIEVE_OPEN: baseUrl + "f-product/open/", // Needs the pk of the f product
    FINAL_PRODUCT_DELETE: baseUrl + "f-product/delete/", // Needs the pk of the f product
    FINAL_PRODUCT_CREATE_IMAGE: baseUrl + "f-product/image/create/",
    FINAL_PRODUCT_UPDATE_IMAGE: baseUrl + "f-product/image/update/", // Needs the pk of the image
    FINAL_PRODUCT_DELETE_IMAGE: baseUrl + "f-product/image/delete/", // Needs the pk of the image
    FINAL_PRODUCT_CATEGORY_CREATE: baseUrl + "f-product/final-product-category/create/",
    FINAL_PRODUCT_CATEGORY_LIST: baseUrl + "f-product/final-product-category/",
    FINAL_PRODUCT_CATEGORIES_TAGS_LIST: baseUrl + "f-product/final-product-categories-tags/",
    FINAL_PRODUCT_CATEGORIES_TAGS_LIST_OPEN: baseUrl + "f-product/final-product-categories-tags-open/",
    //Tags
    TAG_LIST: baseUrl + "tag/",
    //Orders
    ORDERS_LIST: baseUrl + "order/user/", // needs the pk of the user
    ORDER_RETRIEVE: baseUrl + "order/", // needs the pk of the order
    ORDER_PAYMENT_METHODS_AND_TYPES_LIST_OPEN: baseUrl + "order/types-methods-open/",
//    Order Herbs
    HERB_ORDER_LIST_BY_USER: baseUrl + "order/order-herb/user/", // Needs the pk of the user
    HERB_ORDER_RETRIEVE: baseUrl + "order/order-herb/", // Needs the pk of the herb order
    HERB_ORDER_UPDATE: baseUrl + "order/order-herb/update/", // Needs the pk of the herb order
    // Order Herb Item
    HERB_ORDER_ITEM_UPDATE: baseUrl + "order/order-herb-item/update/", // needs the pk of the herb order item
    HERB_ORDER_ITEM_DELETE: baseUrl + "order/order-herb-item/delete/", // needs the pk of the herb order item
    //    Order Product
    PRODUCT_ORDER_LIST_BY_USER: baseUrl + "order/order-product/user/", // Needs the pk of the user
    PRODUCT_ORDER_RETRIEVE: baseUrl + "order/order-product/", // Needs the pk of the herb order
    PRODUCT_ORDER_UPDATE: baseUrl + "order/order-product/update/", // Needs the pk of the herb order
    // Order Product Item
    PRODUCT_ORDER_ITEM_UPDATE: baseUrl + "order/order-product-item/update/", // needs the pk of the herb order item
    PRODUCT_ORDER_ITEM_DELETE: baseUrl + "order/order-product-item/delete/", // needs the pk of the herb order item
    //    Order Final Product
    FINAL_PRODUCT_ORDER_LIST_BY_USER: baseUrl + "order/order-final-product/user/", // Needs the pk of the user
    FINAL_PRODUCT_ORDER_RETRIEVE: baseUrl + "order/order-final-product/", // Needs the pk of the herb order
    FINAL_PRODUCT_ORDER_UPDATE: baseUrl + "order/order-final-product/update/", // Needs the pk of the herb order
    // Order Final Product Item
    FINAL_PRODUCT_ORDER_ITEM_UPDATE: baseUrl + "order/order-final-product-item/update/", // needs the pk of the herb order item
    FINAL_PRODUCT_ORDER_ITEM_DELETE: baseUrl + "order/order-final-product-item/delete/", // needs the pk of the herb order item
    //Order Status
    ORDER_STATUS_LIST: baseUrl + "order/order-status/",
    ORDER_STATUS_ALL_LIST: baseUrl + "order/order-status-all/",
    //Cart
    RETRIEVE_CART_BY_USER: baseUrl + "cart/user/", // Needs the pk of the user
    // Cart Herb Item
    DELETE_CART_HERB_ITEM: baseUrl + "cart/herb-item/delete/", // needs the pk of the order herb item
    UPDATE_CART_HERB_ITEM: baseUrl + "cart/herb-item/update/", // needs the pk of the order herb item
    CREATE_CART_HERB_ITEM: baseUrl + "cart/herb-item/create/", // needs the pk of the order herb item
    // Cart Product Item
    DELETE_CART_PRODUCT_ITEM: baseUrl + "cart/product-item/delete/", // needs the pk of the order product item
    UPDATE_CART_PRODUCT_ITEM: baseUrl + "cart/product-item/update/", // needs the pk of the order product item
    CREATE_CART_PRODUCT_ITEM: baseUrl + "cart/product-item/create/", // needs the pk of the order product item
    // Cart Final Product Item
    DELETE_CART_FINAL_PRODUCT_ITEM: baseUrl + "cart/final-product-item/delete/", // needs the pk of the order final product item
    UPDATE_CART_FINAL_PRODUCT_ITEM: baseUrl + "cart/final-product-item/update/", // needs the pk of the order final product item
    CREATE_CART_FINAL_PRODUCT_ITEM: baseUrl + "cart/final-product-item/create/", // needs the pk of the order final product item
//    Checkout Cart
    CHECKOUT_RETRIEVE_INFORMATION: baseUrl + "cart/checkout/user/", // needs the pk of the user
    CHECKOUT_SUBMIT_ORDER: baseUrl + "order/submit/cart/", // needs the pk of the cart
    CHECKOUT_SUBMIT_ORDER_ANONYMOUS_USER: baseUrl + "order/submit-anonymous/", // needs the pk of the cart
};
