/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
    render() {
        return (
            <footer
                className={"footer" + (this.props.default ? " footer-default" : "")}
            >
                <Container fluid={this.props.fluid ? true : false}>
                    <Row>
                        <nav className="footer-nav">
                            <ul>
                                <li>
                                    {/* <a href="https://www.creative-tim.com" target="_blank">
                                        Q Base R&D
                                    </a> */}
                                </li>
                            </ul>
                        </nav>
                    </Row>
                </Container>
            </footer>
        )
            ;
    }
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool
};

export default Footer;
