import * as actionTypes from '../actions/actionTypes';
import updateObject from "../../../../../utils/updateObject";

const initialState = {
    access: null,
    refresh: null,
    email: null,
    id: null,
    permissions: [],
    isAuthenticated: false,
    panel_access: false,
};


const setUserInformation = (state, action) => {
    return updateObject(state, {
        access: action.access,
        refresh: action.refresh,
        email: action.email,
        id: action.id,
        permissions: action.permissions,
        isAuthenticated: true,
        panel_access: action.panel_access,
    });
};

const setTokens = (state, action) => {
    return updateObject(state, {
        access: action.access,
        refresh: action.refresh,
        email: action.email,
        id: action.id,
        permissions: action.permissions,
        panel_access: action.panel_access,
    });
};

const setAuthenticated = (state, action) => {
    return updateObject(state, {
        isAuthenticated: true,
    });
};


const resetAuth = (state, action) => {
    return updateObject(state, initialState);
};


const setUpdatedTokens = (state, action) => {
    return updateObject(state, {
        access: action.access,
        refresh: action.refresh,
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return setUserInformation(state, action);
        case actionTypes.REFRESH_TOKEN_SUCCESS:
            return setTokens(state, action);
        case actionTypes.SET_AUTH_TRUE:
            return setAuthenticated(state, action);
        case actionTypes.RESET_AUTH:
            return resetAuth(state, action);
        case actionTypes.SET_UPDATED_TOKENS:
            return setUpdatedTokens(state, action);
        default:
            return state;
    }
};

export default reducer;
