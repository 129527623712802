export const TEMPLATE_NAME = "Bio Active Herbs";

export const LANGUAGES = [
    {
        "code": "en",
        "prettier": "English"
    },
    {
        "code": "el",
        "prettier": "Greek"
    }
];

export const GOOGLE_API_KEY = "";
