import React from "react";

const TYPES = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
const POSITION = ["tc", "tl", "tr", "bl", "bc", "br"];

const notificationOptions = (msg, type = "primary", pos = "tc") => {
    if (!TYPES.includes(type))
        type = TYPES[0];

    if (!POSITION.includes(pos))
        pos = POSITION[0];

    if (msg === null || msg === undefined || !typeof "string")
        msg = "";

    return {
        place: pos,
        message: (
            <div>
                <div>
                    {msg}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7
    };
};

export default notificationOptions;
