import * as actionTypes from '../actions/actionTypes';
import updateObject from "../../../utils/updateObject";

const initialState = {
    isLoading: false,
    language: "en",
};


const isLoadingToTrue = (state, action) => {
    return updateObject(state, {isLoading: true});
};

const isLoadingToFalse = (state, action) => {
    return updateObject(state, {isLoading: false});
};

const resetUi = (state, action) => {
    return updateObject(state, initialState);
};
const setLanguage = (state, action) => {
    return updateObject(state, {language: action.language});
};
const initializeLanguage = (state, action) => {
    return updateObject(state, {language: action.language});
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UI_START_LOADING:
            return isLoadingToTrue(state, action);
        case actionTypes.UI_STOP_LOADING:
            return isLoadingToFalse(state, action);
        case actionTypes.RESET_UI:
            return resetUi(state, action);
        case actionTypes.SET_LANGUAGE:
            return setLanguage(state, action);
        case actionTypes.INITIALIZE_LANGUAGE:
            return initializeLanguage(state, action);
        default:
            return state;
    }
};

export default reducer;
