class AsyncStorageHelper {

    setItem = async (key, data, callbackSuccess, callbackError) => {
        if (typeof data === "object")
            data = JSON.stringify(data);
        try {
            await localStorage.setItem(key, data);
            if (callbackSuccess && typeof callbackSuccess === "function")
                callbackSuccess();
        } catch (e) {
            console.error(e);
            if (callbackError && typeof callbackError === "function")
                callbackError(e);
        }
        return this;
    };

    getItem = async (key, callbackSuccess, callbackError) => {
        try {
            let value = await localStorage.getItem(key);
            if (callbackSuccess && typeof callbackSuccess === "function") {
                try {
                    value = JSON.parse(value);
                } catch (e) {
                }
                callbackSuccess(value);
            }
        } catch (e) {
            console.error(e);
            if (callbackError && typeof callbackError === "function")
                callbackError(e);
        }
        return this;
    };

    removeItem = async (key, callbackSuccess, callbackError) => {
        try {
            await localStorage.removeItem(key)
        } catch (e) {
            console.error(e);
            if (callbackError && typeof callbackError === "function")
                callbackError(e);
        }
        if (callbackSuccess && typeof callbackSuccess === "function")
            callbackSuccess();
        return this;
    }
}

export default AsyncStorageHelper;
