import language from "../../utils/languageSelector";
import CookieConsent from "react-cookie-consent";
import React from "react";

class MyCookieConsent extends React.Component {
    render() {
        const {content} = this.props;

        return (
            <CookieConsent
                location="bottom"
                buttonText={language(this.props.language)["cookie_gdbr_button_accept"]}
                buttonClasses="btn btn-primary"
                containerClasses="col-lg-12"
                contentClasses="text-capitalize"
                buttonStyle={{
                    background: "#1E5631",
                    color: "white",
                    textShadow: "1px 1px black",
                }}
            >
                {content}
            </CookieConsent>
        )
    };
}

export default MyCookieConsent;
