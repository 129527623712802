import {LANGUAGES} from "../settings";

import en from '../locale/english.json';
import el from '../locale/greek.json';

const selectLanguage = (language) => {
    let found = false;


    for (let i = 0; i < LANGUAGES.length; i++) {
        if (LANGUAGES[i].code === language) {
            found = true;
            break;
        }
    }

    if (found && language === "el") {
        return el;
    } else {
        return en;
    }
};

export default selectLanguage;
