/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {lazy} from 'react';

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }
  
            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }

const Dashboard = lazy(() => retry(() => import('./views/Dashboard/Dashboard/Dashboard.jsx')));
const Notifications = lazy(() => retry(() => import('./views/Notifications.jsx')));
const Icons = lazy(() => retry(() => import('./views/Icons.jsx')));
const Typography = lazy(() => retry(() => import('./views/Typography.jsx')));
const TableList = lazy(() => retry(() => import('./views/Tables.jsx')));
const Maps = lazy(() => retry(() => import('./views/Map.jsx')));
const UserPage = lazy(() => retry(() => import('./views/User.jsx')));
const Blog = lazy(() => retry(() => import('./views/Dashboard/Blog/Blog.jsx')));
const DetailBlog = lazy(() => retry(() => import('./views/Dashboard/Blog/DetailBlog.jsx')));
const Profile = lazy(() => retry(() => import('./views/Dashboard/Profile/Profile.jsx')));
const Herb = lazy(() => retry(() => import('./views/Dashboard/Herb/Herb.jsx')));
const HerbDetails = lazy(() => retry(() => import('./views/Dashboard/Herb/HerbDetails.jsx')));
const Product = lazy(() => retry(() => import('./views/Dashboard/Product/Product.jsx')));
const ProductDetails = lazy(() => retry(() => import('./views/Dashboard/Product/ProductDetails.jsx')));
const FinalProduct = lazy(() => retry(() => import('./views/Dashboard/FinalProduct/FinalProduct.jsx')));
const FinalProductDetails = lazy(() => retry(() => import('./views/Dashboard/FinalProduct/FinalProductDetails')));
const OrderHerb = lazy(() => retry(() => import('./views/Dashboard/OrderHerb/OrderHerb.jsx')));
const OrderHerbDetails = lazy(() => retry(() => import('./views/Dashboard/OrderHerb/OrderHerbDetails.jsx')));
const OrderProduct = lazy(() => retry(() => import('./views/Dashboard/OrderProduct/OrderProduct.jsx')));
const OrderProductDetails = lazy(() => retry(() => import('./views/Dashboard/OrderProduct/OrderProductDetails.jsx')));
const OrderFinalProduct = lazy(() => retry(() => import('./views/Dashboard/OrderFinalProduct/OrderFinalProduct.jsx')));
const OrderFinalProductDetails = lazy(() => retry(() => import('./views/Dashboard/OrderFinalProduct/OrderFinalProductDetails.jsx')));
const DashboardCart = lazy(() => retry(() => import('./views/Dashboard/DashboardCart/DashboardCart.jsx')));
const DashboardCartCheckout = lazy(() => retry(() => import('./views/Dashboard/DashboardCart/DashboardCartCheckout.jsx')));
const MyOrders = lazy(() => retry(() => import('./views/Dashboard/Orders/MyOrders.jsx')));
const MyOrderDetails = lazy(() => retry(() => import('./views/Dashboard/Orders/MyOrderDetails.jsx')));
const Calendar = lazy(() => retry(() => import('./views/Dashboard/Calendar/Calendar.jsx')));


const dashboardRoutes = [
    {
        path: "/dashboard",
        name_el: "Αρχική",
        name_en: "Dashboard",
        icon: "nc-icon nc-bank",
        component: Dashboard,
        show: true,
        layout: "",
        permissions: []
    },
    {
        path: "/herb",
        name_el: "Βότανα",
        name_en: "Herb",
        icon: "fas fa-leaf",
        component: Herb,
        show: true,
        layout: "",
        permissions: ["herb.view_herb"]
    },
    {
        path: "/herb/detail/:id",
        name_el: "Βότανα",
        name_en: "Herb",
        icon: "",
        component: HerbDetails,
        show: false,
        layout: "",
        permissions: ["herb.view_herb"]
    },
    {
        path: "/product",
        name_el: "Προιόντα",
        name_en: "Products",
        icon: "fas fa-box-open",
        component: Product,
        show: true,
        layout: "",
        permissions: ["product.view_product"]
    },
    {
        path: "/product/detail/:id",
        name_el: "Βότανα",
        name_en: "Herb",
        icon: "",
        component: ProductDetails,
        show: false,
        layout: "",
        permissions: ["herb.view_herb"]
    },
    {
        path: "/herb-order/:id",
        name_el: "Παραγγελίες Βοτάνων",
        name_en: "Herb Orders",
        icon: "nc-icon nc-tile-56",
        component: OrderHerbDetails,
        show: false,
        layout: "",
        permissions: ["order.change_orderherbitem"]
    },
    {
        path: "/herb-order",
        name_el: "Παραγγελίες Βοτάνων",
        name_en: "Herb Orders",
        icon: "nc-icon nc-tile-56",
        component: OrderHerb,
        show: true,
        layout: "",
        permissions: ["order.view_order", "order.view_orderherbitem"]
    },
    {
        path: "/product-order",
        name_el: "Παραγγελίες Προιόντων",
        name_en: "Product Orders",
        icon: "nc-icon nc-tile-56",
        component: OrderProduct,
        show: true,
        layout: "",
        permissions: ["order.view_order", "order.view_orderproductitem"]
    },
    {
        path: "/product-order/:id",
        name_el: "Παραγγελίες Προιόντων Λεπτομέριες",
        name_en: "Product Order Details",
        icon: "nc-icon nc-tile-56",
        component: OrderProductDetails,
        show: false,
        layout: "",
        permissions: ["order.change_orderproductitem"]
    },
    {
        path: "/f-product-order",
        name_el: "Παραγγελίες Τελικών Προιόντων",
        name_en: "Final Product Orders",
        icon: "nc-icon nc-tile-56",
        component: OrderFinalProduct,
        show: true,
        layout: "",
        permissions: ["order.view_order", "order.view_orderfinalproductitem"]
    },
    {
        path: "/f-product-order/:id",
        name_el: "Παραγγελίες Τελικών Προιόντων Λεπτομέριες",
        name_en: "Final Product Order Details",
        icon: "nc-icon nc-tile-56",
        component: OrderFinalProductDetails,
        show: false,
        layout: "",
        permissions: ["order.change_orderfinalproductitem"]
    },
    {
        path: "/product/detail/:id",
        name_el: "Προιόντα",
        name_en: "Προιόντα",
        icon: "",
        component: ProductDetails,
        show: false,
        layout: "",
        permissions: ["product.view_product"]
    },
    {
        path: "/f-product",
        name_el: "Τελικά προιόντα",
        name_en: "Final Products",
        icon: "fas fa-box-open",
        component: FinalProduct,
        show: true,
        layout: "",
        permissions: ["final_product.view_finalproduct"]
    },
    {
        path: "/f-product/detail/:id",
        name_el: "Τελικό Προιόν",
        name_en: "final Product",
        icon: "",
        component: FinalProductDetails,
        show: false,
        layout: "",
        permissions: ["final_product.view_finalproduct"]
    },
    {
        path: "/blog",
        name_el: "Blog",
        name_en: "Blog",
        icon: "fab fa-blogger-b",
        component: Blog,
        show: true,
        layout: "",
        permissions: ["blog.view_blog"]
    },
    {
        path: "/blog/detail/:id",
        name_el: "Στοιχεία Blog",
        name_en: "Blog Details",
        name: "Details Blog",
        icon: "",
        component: DetailBlog,
        show: false,
        layout: "",
        permissions: ["blog.change_blog"]
    },
    {
        path: "/order",
        name_el: "Οι παραγγελίες μου",
        name_en: "My Orders",
        icon: "fas fa-prescription-bottle",
        component: MyOrders,
        show: true,
        layout: "",
        permissions: ["order.add_order"]
    },
    {
        path: "/order/detail/:id",
        name_el: "",
        name_en: "",
        icon: "",
        component: MyOrderDetails,
        show: false,
        layout: "",
        permissions: ["order.add_order"]
    },
    {
        path: "/profile",
        name_el: "Προφίλ",
        name_en: "Profile",
        icon: "",
        component: Profile,
        show: false,
        layout: "",
        permissions: ["user.view_customuser"]
    },
    {
        path: "/cart/checkout",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: DashboardCartCheckout,
        show: false,
        layout: "",
        permissions: ["cart.view_cart"]
    },
    {
        path: "/cart",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: DashboardCart,
        show: false,
        layout: "",
        permissions: ["cart.view_cart"]
    },
    // {
    //     path: "/calendar",
    //     name_en: "",
    //     name_el: "",
    //     icon: "nc-icon nc-cart-simple",
    //     component: Calendar,
    //     show: true,
    //     layout: "",
    //     permissions: []
    // }
];
export default dashboardRoutes;
