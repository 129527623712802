import React, {Component} from 'react';
import {connect} from "react-redux";
import {resetAuth} from "../Login/store/actions/authentication";
import {resetUi} from "../../../configuration/redux/actions/ui";
import {withRouter} from "react-router-dom";
import asyncStorageKeys from "../../../constants/asyncStorageKeys";
import AsyncStorageHelper from "../../../helpers/AsyncStorageHelper";
import {resetCart} from "../../Root/RootCart/store/actions/cart";

class Logout extends Component {
    componentDidMount() {
        new AsyncStorageHelper().removeItem(asyncStorageKeys.tokens);
        //Clear memory
        this.props.resetUi();
        this.props.resetAuth();
        this.props.resetCart();
        //Remove all the stored variables and redirect to the homepage
        this.props.history.push("/home")
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        loading: state.ui.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetAuth: () => dispatch(resetAuth()),
        resetUi: () => dispatch(resetUi()),
        resetCart: () => dispatch(resetCart()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
