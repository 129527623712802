import {lazy} from 'react';

const Login = lazy(() => import('./views/Authentication/Login/Login.jsx'));
const Home = lazy(() => import('./views/Root/Home/Home.jsx'));
const Shop = lazy(() => import('./views/Root/Shop/Shop.jsx'));
const RootCart = lazy(() => import('./views/Root/RootCart/RootCart'));
const RootCartCheckout = lazy(() => import('./views/Root/RootCart/RootCartCheckout'));
const Signup = lazy(() => import('./views/Authentication/Signup/Signup.jsx'));
const ForgotPassword = lazy(() => import('./views/Authentication/ForgotPassword/ForgotPassword'));
const Blog = lazy(() => import('./views/Root/Blog/Blog.jsx'));
const DetailBlog = lazy(() => import('./views/Dashboard/Blog/DetailBlog.jsx'));
const ShopFinalProductDetails = lazy(() => import('./views/Root/Shop/ShopFinalProductDetails.jsx'));
const Logout = lazy(() => import('./views/Authentication/Logout/Logout.jsx'));
const Profile = lazy(() => import('./views/Root/Profile/Profile.jsx'));
const Orders = lazy(() => import('./views/Root/Orders/MyOrders.jsx'));
const MyOrderDetails = lazy(() => import('./views/Root/Orders/MyOrderDetails.jsx'));
const NotFound404 = lazy(() => import('./views/ErrorView/404.jsx'));


const rootSimplePermsRoutes = [
    {
        path: "/home",
        name_en: "Home",
        name_el: "Αρχική",
        icon: "",
        component: Home,
        show: true,
        layout: "",
        permissions: [],
    },
    {
        path: "/blog",
        name_en: "Blog",
        name_el: "Δημοσιεύσεις",
        icon: "",
        component: Blog,
        show: true,
        layout: "",
        permissions: [],
    },
    {
        path: "/blog/detail/:id",
        name_el: "Στοιχεία Blog",
        name_en: "Blog Details",
        name: "Details Blog",
        icon: "",
        component: DetailBlog,
        show: false,
        layout: "",
        permissions: [],
    },
    {
        path: "/shop",
        name_en: "Shop",
        name_el: "Κατάστημα",
        icon: "",
        component: Shop,
        show: true,
        layout: "",
        permissions: [],
    },
    {
        path: "/shop/product/:id",
        name_el: "",
        name_en: "",
        icon: "",
        component: ShopFinalProductDetails,
        show: false,
        layout: "",
        permissions: [],
    },
    {
        path: "/login",
        name_en: "Login",
        name_el: "Σύνδεση",
        icon: "",
        component: Login,
        show: false,
        layout: "",
        permissions: [],
    },
    {
        path: "/signup",
        name_en: "Signup",
        name_el: "Εγγραφή",
        icon: "",
        component: Signup,
        show: false,
        layout: "",
        permissions: [],
    },
    {
        path: "/logout",
        name_en: "Logout",
        name_el: "Αποσύνδεση",
        icon: "",
        component: Logout,
        show: false,
        layout: "",
        permissions: [],
    },
    {
        path: "/profile",
        name_en: "Profile",
        name_el: "Προφίλ",
        icon: "",
        component: Profile,
        show: false,
        layout: "",
        permissions: ["user.view_customuser"]
    },
    {
        path: "/forgot-password",
        name_en: "",
        name_el: "",
        icon: "",
        component: ForgotPassword,
        show: false,
        layout: "",
        permissions: [],
    },
    {
        path: "/order",
        name_en: "",
        name_el: "",
        icon: "",
        component: Orders,
        show: false,
        layout: "",
        permissions: ["order.view_order"]
    },
    {
        path: "/order/detail/:id",
        name_el: "",
        name_en: "",
        icon: "",
        component: MyOrderDetails,
        show: false,
        layout: "",
        permissions: ["order.view_order"]
    },
    {
        path: "/cart",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: RootCart,
        show: false,
        layout: "",
        permissions: ["cart.view_cart"]
    },
    {
        path: "/cart/checkout",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: RootCartCheckout,
        show: false,
        layout: "",
        permissions: ["cart.view_cart"]
    },
    {
        path: "/404",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: NotFound404,
        show: false,
        layout: "",
        permissions: []
    },
];
export default rootSimplePermsRoutes;
