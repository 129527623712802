import * as actionTypes from './actionTypes';
import AsyncStorageHelper from "../../../helpers/AsyncStorageHelper";
import asyncStorageKeys from "../../../constants/asyncStorageKeys";

export const uiStartLoading = () => {
    return {
        type: actionTypes.UI_START_LOADING
    };
};

export const uiStopLoading = () => {
    return {
        type: actionTypes.UI_STOP_LOADING
    };
};

export const resetUi = () => {
    return {
        type: actionTypes.RESET_UI,
    };
};

export const setLanguage = (language) => {
    new AsyncStorageHelper().setItem(asyncStorageKeys.language, language);

    return {
        type: actionTypes.SET_LANGUAGE,
        language: language,
    };
};

export const initializeLanguage = (language) => {
    if (language === null)
        language = "en";

    return {
        type: actionTypes.INITIALIZE_LANGUAGE,
        language: language,
    };
};

