/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {lazy} from "react";
import {NavLink} from "react-router-dom";
import {Nav} from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import logo from "logo.svg";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import "./Sidebar.scss";

let ps;

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.activeRoute.bind(this);
        this.sidebar = React.createRef();
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    render() {
        return (
            <div
                className="sidebar">
                <div className="logo sidebar-header text-center">
                    <a href="#"
                       className="logo-normal text-small text-white">
                        Bio Active Herbs
                    </a>
                </div>
                <div className="sidebar-wrapper" ref={this.sidebar}>
                    <Nav>
                        {this.props.routes.map((prop, key) => {
                            let access = true;
                            if (prop.permissions.length !== 0)
                                for (let i = 0; i < prop.permissions.length; i++) {
                                    if (!this.props.permissions.includes(prop.permissions[i])) {
                                        access = false;
                                    }
                                    if (!access)
                                        break;
                                }
                            if (prop.show) {
                                return (
                                    access ? <li key={key}>
                                            <NavLink
                                                exact
                                                to={prop.layout + prop.path}
                                                className="nav-link"
                                                activeClassName="active">
                                                <i className={prop.icon}/>
                                                <p> {this.props.language === "el" ? prop.name_el : prop.name_en}</p>
                                            </NavLink>
                                        </li>
                                        : null
                                );
                            }
                        })}
                    </Nav>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated,
        permissions: state.authentication.permissions,
        language: state.ui.language,
    };
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));
