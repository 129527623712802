import React, {Fragment, lazy, Suspense} from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {Redirect, Route, Switch} from "react-router-dom";


import DashboardNavBar from "../components/Navbars/DashboardNavBar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "../dashboardRoutes.js";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Logout from "../views/Authentication/Logout/Logout";
import notificationOptions from "../utils/notificationOptions";
import CustomLoader from "../components/CustomLoader/CustomLoader";
const NotFound404 = lazy(() => import('../views/ErrorView/404.jsx'));

let ps;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        //Passed Reference for global alerts inside View Components
        this.mainPanel = React.createRef();
    }

    componentDidMount() {
        // this.props.notificationAlert.current.notificationAlert(notificationOptions("hello from dashboard"));
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }

    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            this.mainPanel.current.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                    routes={dashboardRoutes}
                />
                <div className="main-panel"
                     ref={this.mainPanel}>
                    <Suspense fallback={<CustomLoader/>}>
                        <DashboardNavBar {...this.props} />
                        <Switch>
                            {dashboardRoutes.map((prop, key) => {
                                return (
                                    <Route
                                        exact
                                        path={prop.layout + prop.path}
                                        component={() => <prop.component
                                            {...this.props}/>
                                        }
                                        key={key}
                                    />
                                );
                            })}
                            <Route path="/logout"
                                   component={Logout}
                            />
                            <Redirect component={Dashboard} to="/dashboard"/>
                        </Switch>
                        <Footer fluid/>
                    </Suspense>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated
    };
};

export default connect(mapStateToProps, null)(withRouter(Dashboard));
