import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {NavLink} from "reactstrap";

class NavLinkWithPermission extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {title, permissions_required} = this.props;
        let access = true;
        if (permissions_required === undefined || permissions_required === null)
            permissions_required = [];
        for (let i = 0; i < permissions_required.length; i++) {
            if (!this.props.permissions.includes(permissions_required[i])) {
                access = false;
                break;
            }
        }
        console.log(access)
        return (
            access ?
                <NavLink {...this.props}>
                    {title}
                    {this.props.children}
                </NavLink>
                : null
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated,
        permissions: state.authentication.permissions,
    };
};

export default connect(mapStateToProps, null)(withRouter(NavLinkWithPermission));
