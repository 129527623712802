//Cart types
export const ADD_HERB_ITEM_CART = 'ADD_HERB_ITEM_CART';
export const ADD_HERB_ITEM_SUCCESS = 'ADD_HERB_ITEM_SUCCESS';
export const ADD_HERB_ITEM_FAIL = 'ADD_HERB_ITEM_FAIL';

export const REMOVE_HERB_ITEM_CART = 'REMOVE_HERB_ITEM_CART';
export const REMOVE_HERB_ITEM_CART_SUCCESS = 'REMOVE_HERB_ITEM_CART_SUCCESS';
export const REMOVE_HERB_ITEM_CART_FAIL = 'REMOVE_HERB_ITEM_CART_FAIL';

export const SET_TOTAL_BADGE = "SET_TOTAL_BADGE";
export const INCREMENT_CART_BADGE = "INCREMENT_BADGE";
export const DECREMENT_CART_BADGE = "DECREMENT_CART_BADGE";

export const CART_ID_ACTION = "CART_ID_ACTION";

export const CLEAR_CART_HERB = 'CLEAR_CART_HERB';
export const RESET_CART = 'RESET_CART';
