import {lazy} from 'react';

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }
  
            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }

  const Login = lazy(() => retry(() =>  import('./views/Authentication/Login/Login.jsx')));
const Home = lazy(() => retry(() =>  import('./views/Root/Home/Home.jsx')));
const Shop = lazy(() => retry(() =>  import('./views/Root/Shop/Shop.jsx')));
const RootCart = lazy(() => retry(() =>  import('./views/Root/RootCart/RootCart.jsx')));
const NonAuthRootCartCheckout = lazy(() => retry(() =>  import('./views/Root/RootCart/NonAuthRootCartCheckout.jsx')));
const Signup = lazy(() => retry(() =>  import('./views/Authentication/Signup/Signup.jsx')));
const ForgotPassword = lazy(() => retry(() =>  import('./views/Authentication/ForgotPassword/ForgotPassword')));
const Blog = lazy(() => retry(() =>  import('./views/Root/Blog/Blog.jsx')));
const DetailBlog = lazy(() => retry(() =>  import('./views/Dashboard/Blog/DetailBlog.jsx')));
const ShopFinalProductDetails = lazy(() => import('./views/Root/Shop/ShopFinalProductDetails.jsx'));
const Logout = lazy(() => retry(() =>  import('./views/Authentication/Logout/Logout.jsx')));
const Profile = lazy(() => retry(() =>  import('./views/Root/Profile/Profile.jsx')));
const NotFound404 = lazy(() => retry(() =>  import('./views/ErrorView/404.jsx')));


const rootRoutes = [
    {
        path: "/",
        name_en: "Home",
        name_el: "Αρχική",
        icon: "",
        component: Home,
        show: false,
        layout: "",
    },
    {
        path: "/home",
        name_en: "Home",
        name_el: "Αρχική",
        icon: "",
        component: Home,
        show: true,
        layout: "",
    },
    {
        path: "/blog",
        name_en: "Blog",
        name_el: "Δημοσιεύσεις",
        icon: "",
        component: Blog,
        show: true,
        layout: "",
    },
    {
        path: "/blog/detail/:id",
        name_el: "Στοιχεία Blog",
        name_en: "Blog Details",
        name: "Details Blog",
        icon: "",
        component: DetailBlog,
        show: false,
        layout: "",
    },
    {
        path: "/shop",
        name_en: "Shop",
        name_el: "Κατάστημα",
        icon: "",
        component: Shop,
        show: true,
        layout: "",
    },
    {
        path: "/shop/product/:id",
        name_el: "",
        name_en: "",
        icon: "",
        component: ShopFinalProductDetails,
        show: false,
        layout: "",
    },
    {
        path: "/login",
        name_en: "Login",
        name_el: "Σύνδεση",
        icon: "",
        component: Login,
        show: false,
        layout: "",
    },
    {
        path: "/signup",
        name_en: "Signup",
        name_el: "Εγγραφή",
        icon: "",
        component: Signup,
        show: false,
        layout: "",
    },
    {
        path: "/forgot-password",
        name_en: "",
        name_el: "",
        icon: "",
        component: ForgotPassword,
        show: false,
        layout: "",
    },
    {
        path: "/cart",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: RootCart,
        show: false,
        layout: "",
    },
    {
        path: "/cart/checkout",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: NonAuthRootCartCheckout,
        show: false,
        layout: "",
        permissions: ["cart.view_cart"]
    },
    {
        path: "/404",
        name_en: "",
        name_el: "",
        icon: "nc-icon nc-cart-simple",
        component: NotFound404,
        show: false,
        layout: "",
    },
];
export default rootRoutes;
