import React, {Fragment, Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import NotificationAlert from "react-notification-alert";


import Dashboard from "../src/layouts/Dashboard.jsx";
import asyncStorageKeys from "./constants/asyncStorageKeys";
import AsyncStorageHelper from "./helpers/AsyncStorageHelper";
import {autoAuthenticate} from "./views/Authentication/Login/store/actions/authentication";
import Root from "./layouts/Root";
import {initializeLanguage} from "./configuration/redux/actions/ui";

import './components/CustomForm/CustomForm.scss';
import CustomLoader from "./components/CustomLoader/CustomLoader";
import RootSimplePerms from "./layouts/RootSimplePerms";
import MyCookieConsent from "./components/MyCookieConsent/MyCookieConsent";
import language from "./utils/languageSelector";
import Dexie from "dexie";
import {setTotalBadge} from "./views/Root/RootCart/store/actions/cart";
import calculateDiscount from "./utils/calculateDiscount";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.notificationAlert = React.createRef();
    }

    state = {
        visible: true,
        loading: true,
    };

    componentDidMount() {
        const self = this;

        Dexie.exists('cart').then(function (exists) {
            const database = new Dexie("cart");
            database.version(1).stores({
                cart: '++id,total_items,total_price,date_created',
                product: 'id,final_product_name,final_product_el_name,price,discount,quantity,image'
            });

            if (!exists) {
                database.open().then(function () {
                    return database.cart.put({id: 1, total_items: 0, total_price: 0, date_created: new Date()});
                });
            } else {
                database.open().then(function () {
                    database.product.toArray().then(products => {
                        let total_price = 0;
                        for(let i = 0; i < products.length; i++)
                            total_price = calculateDiscount(products[i].price, products[i].quantity, products[i].discount);
                        database.cart.put({id: 1, total_items: 0, total_price: total_price, date_created: new Date()});
                        self.props.setTotalBadge(products.length);
                    });
                });
            }
        });


        //Set language to memory from locale Storage
        new AsyncStorageHelper().getItem(asyncStorageKeys.language, (language) => {
            this.props.initializeLanguage(language);
        });

        new AsyncStorageHelper().getItem(asyncStorageKeys.tokens, (data) => {
            if (data) {
                //Auto refresh token
                this.props.autoAuthenticate(data.access, data.refresh, this.props.history);
            }
        });

        //Hacky timeout makes the browser wait for the updated changes from requests(redux)
        setTimeout(() => {
            this.setState({loading: false});
        }, 100)
    }


    render() {

        let routes =
            <Route
                render={props =>
                    <Root
                        notificationAlert={this.notificationAlert !== undefined ? this.notificationAlert : React.createRef}
                        {...props}
                    />
                }
            />;

        if (this.props.isAuthenticated) {
            routes =
                <Route
                    render={props =>
                        <RootSimplePerms
                            notificationAlert={this.notificationAlert !== undefined ? this.notificationAlert : React.createRef}
                            {...props}
                        />
                    }
                />;

            if (this.props.panel_access) {
                routes =
                    <Route
                        render={props =>
                            <Dashboard
                                notificationAlert={this.notificationAlert !== undefined ? this.notificationAlert : React.createRef}
                                {...props}
                            />
                        }/>
            }

        }

        return (
            <Fragment>
                <NotificationAlert
                    ref={this.notificationAlert}
                />
                {this.state.loading ? <CustomLoader/> :
                    <Switch>
                        {routes}
                    </Switch>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated,
        panel_access: state.authentication.panel_access,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        autoAuthenticate: (access, refresh, history) => dispatch(autoAuthenticate(access, refresh, history)),
        initializeLanguage: (language) => dispatch(initializeLanguage(language)),
        setTotalBadge: (badge) => dispatch(setTotalBadge(badge)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
