import * as actionTypes from './actionTypes';

const addHerbItemToCartAction = () => {
    return {
        type: actionTypes.ADD_HERB_ITEM_CART,
    };
};

const addHerbItemToCartActionSuccess = (herb) => {
    return {
        type: actionTypes.ADD_HERB_ITEM_SUCCESS,
        herb: herb,
    };
};


const addHerbItemToCartActionFail = () => {
    return {
        type: actionTypes.ADD_HERB_ITEM_FAIL,
    };
};

export const addHerbItemToCart = (herb) => {
    return async (dispatch, done) => {
        dispatch(addHerbItemToCartAction());
        dispatch(addHerbItemToCartActionSuccess(herb));
    };
};


const removeItemFromCartAction = () => {
    return {
        type: actionTypes.REMOVE_HERB_ITEM_CART,
    };
};

const removeItemFromCartActionSuccess = (item) => {
    return {
        type: actionTypes.REMOVE_HERB_ITEM_CART_SUCCESS,
        item: item,
    };
};


const removeItemFromCartActionFail = () => {
    return {
        type: actionTypes.REMOVE_HERB_ITEM_CART_FAIL,
    };
};

export const removeItemFromCart = (item) => {
    return async (dispatch, done) => {
        dispatch(removeItemFromCartAction());
        dispatch(removeItemFromCartActionSuccess(item));
    };
};

// Total Badge ~~~~~~~~~~~~~~~~~~~~~
const totalBadgeAction = (badge) => {
    return {
        type: actionTypes.SET_TOTAL_BADGE,
        badge: badge,
    };
};


const cartIdAction = (cart_id) => {
    return {
        type: actionTypes.CART_ID_ACTION,
        cart_id: cart_id,
    };
};

export const setCartId = (cart_id) => {
    return async (dispatch, done) => {
        dispatch(cartIdAction(cart_id));
    };
};


export const setTotalBadge = (badge) => {
    return async (dispatch, done) => {
        dispatch(totalBadgeAction(badge));
    };
};


//Increment badge
export const incrementBadgeAction = () => {
    return {
        type: actionTypes.INCREMENT_CART_BADGE,
    };
};

//Decrement badge
export const decrementBadgeAction = () => {
    return {
        type: actionTypes.DECREMENT_CART_BADGE,
    };
};

//Reset the cart
export const resetCart = () => {
    return {
        type: actionTypes.RESET_CART,
    };
};

