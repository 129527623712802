import React from "react";

import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Col, Container, Row} from "reactstrap";
import './RootHeader.scss';
import {NavLink} from "react-router-dom";
import language from '../../utils/languageSelector';
import capitalize from "../../utils/capitalize";
import PermissionFilter from "../PermissionFilter/PermissionFilter";

class RootHeader extends React.Component {

    render() {
        let routes =
            <Col className="text-right">
                <a className="btn-floating btn-sm btn-tw text-primary header-outline" type="button" role="button">
                    <NavLink to="/login">
                        <a className="text-sm-center">{capitalize(language(this.props.language)["login"])}</a>
                    </NavLink>
                </a>
                <a className="btn-floating btn-sm btn-tw text-primary header-outline" type="button" role="button">
                    <NavLink to="/signup">
                        <a className="text-sm-center">{capitalize(language(this.props.language)["signup"])}</a>
                    </NavLink>
                </a>
            </Col>;

        if (this.props.isAuthenticated) {
            routes =
                <Col className="text-right">
                    <small>{language(this.props.language)["logged_in_as"]} <b
                        className="text-primary">{this.props.email}</b></small>
                    <br/>
                    <PermissionFilter permissions_required={["order.view_order"]}>
                        <a className="btn-floating btn-sm btn-tw text-primary header-outline" type="button" role="button">
                            <NavLink to="/order">
                                <a className="text-sm-center">{capitalize(language(this.props.language)["orders"])}</a>
                            </NavLink>
                        </a>
                    </PermissionFilter>
                    <PermissionFilter permissions_required={["user.view_customuser"]}>
                        <a className="btn-floating btn-sm btn-tw text-primary header-outline" type="button" role="button">
                            <NavLink to="/profile">
                                <a className="text-sm-center">{capitalize(language(this.props.language)["profile"])}</a>
                            </NavLink>
                        </a>
                    </PermissionFilter>
                    <a className="btn-floating btn-sm btn-tw text-primary header-outline" type="button" role="button">
                        <NavLink to="/logout">
                            <a className="text-sm-center">{capitalize(language(this.props.language)["logout"])}</a>
                        </NavLink>
                    </a>
                </Col>;
        }

        return (
            <Container fluid>
                <Row className="header">
                    <Col className="text-left">
                        {this.props.isAuthenticated}
                        <img src={require("../../assets/img/bioactherb_logo.png")}
                             alt="logo bioactiveherbs"
                             className="img-logo-responsive img-responsive"
                        />
                    </Col>
                    {routes}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated,
        language: state.ui.language,
        email: state.authentication.email,
    };
};

export default connect(mapStateToProps, null)(withRouter(RootHeader));
