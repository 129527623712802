import React from 'react';

import "./Loader.scss";

const CustomLoader = () => {
    return (
        <div className="content">
            <div className="row mx-auto justify-content-center align-items-center flex-column ">
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default CustomLoader;
