import urls from '../constants/urls';
import AsyncStorageHelper from "./AsyncStorageHelper";
import asyncStorageKeys from "../constants/asyncStorageKeys";
import {autoAuthenticate} from "../views/Authentication/Login/store/actions/authentication";


const urlRefreshToken = urls.REFRESH_TOKEN;

//
class UrlRequest {
    constructor(url, token, refreshMemoryTokens = null, hasFile = false) {
        this.url = url ? url : null;
        //If has token then add the required header configuration
        this.token = token ? token : null;

        this.contentType = !hasFile ? "application/json" : "multipart/form-data";
        //If the form has files should be sent as multipart
        this.hasFile = hasFile;
        //If theres a function for the refresh tokens inside memory then execute it when required.
        this.refreshMemoryTokens = refreshMemoryTokens !== undefined ? refreshMemoryTokens : null;
        //Construct.
        this.init();
    }

    setHistory = (history) => {
        this.history = history;
        return this;
    };

    init() {
        // Check if the url is not null, debug option
        if (this.url !== null)
            this.constructHeaders();
        else
            console.warn("URL REQUIRED INSIDE URLREQUEST CLASS");
    }

    constructHeaders = () => {
        //If theres no token present, then the request doesn't require authentication
        if (!this.token) {
            this.headers = {
                'Content-Type': this.contentType,
            }
        } else {
            //Otherwise set the token inside headers of the request
            this.headers = {
                'Content-Type': this.contentType,
                "Authorization": "Bearer " + this.token
            }
        }
    };

    postMethod = (body, callbackSuccess, callbackFailure) => {
        this.body = body;
        let method = "POST";
        let headers = {
            method: method,
            headers: this.headers,
            body: JSON.stringify(this.body),
        };
        if (this.hasFile) {
            delete headers["headers"]["Content-Type"];
            delete headers["body"];
            headers["body"] = this.body;
        }

        fetch(this.url, headers)
            .then((initialResponse) => {
                const status = initialResponse.status;
                //Unauthorized response
                if (status === 401) {
                    console.warn("Token needs refresh");
                    new AsyncStorageHelper().getItem(asyncStorageKeys.tokens,
                        (initialStorageData) => {
                            this.refreshToken(initialResponse, initialStorageData, callbackSuccess, callbackFailure, method, status);
                        })
                } else {
                    //Other status codes are handled in each request callback
                    initialResponse.json().then(data => {
                        callbackSuccess(data, status);
                    });
                }

            })
            .catch((error) => {
                //General callback for all errors
                callbackFailure(error);
            });
        return this;
    };

    putMethod = (body, callbackSuccess, callbackFailure) => {
        this.body = body;
        let method = "PUT";
        let headers = {
            method: method,
            headers: this.headers,
            body: JSON.stringify(this.body),
        };
        if (this.hasFile) {
            delete headers["headers"]["Content-Type"];
            delete headers["body"];
            headers["body"] = this.body;
        }
        fetch(this.url, headers, {
            method: method,
            headers: this.headers,
            body: JSON.stringify(this.body),
        })
            .then((initialResponse) => {
                const status = initialResponse.status;
                //Unauthorized response
                if (status === 401) {
                    console.warn("Token needs refresh");
                    new AsyncStorageHelper().getItem(asyncStorageKeys.tokens,
                        (initialStorageData) => {
                            this.refreshToken(initialResponse, initialStorageData, callbackSuccess, callbackFailure, method, status);
                        })
                } else {
                    //Other status codes are handled in each request callback
                    initialResponse.json().then(data => {
                        callbackSuccess(data, status);
                    });
                }

            })
            .catch((error) => {
                //General callback for all errors
                callbackFailure(error);
            });
        return this;
    };

    getMethod = (callbackSuccess, callbackFailure) => {
        // this.body = body;
        let method = "GET";
        fetch(this.url, {
            method: method,
            headers: this.headers,
            // body: JSON.stringify(this.body),
        })
            .then((initialResponse) => {
                const status = initialResponse.status;
                //Unauthorized response
                if (status === 401) {
                    console.warn("Token needs refresh");
                    new AsyncStorageHelper().getItem(asyncStorageKeys.tokens,
                        (initialStorageData) => {
                            this.refreshToken(initialResponse, initialStorageData, callbackSuccess, callbackFailure, method, status);
                        })
                } else {
                    //Other status codes are handled in each request callback
                    initialResponse.json().then(data => {
                        callbackSuccess(data, status);
                    });
                }

            })
            .catch((error) => {
                //General callback for all errors
                callbackFailure(error);
            });
        return this;
    };

    deleteMethod = (callbackSuccess, callbackFailure) => {
        // this.body = body;
        let method = "DELETE";
        fetch(this.url, {
            method: method,
            headers: this.headers,
            // body: JSON.stringify(this.body),
        })
            .then((initialResponse) => {
                const status = initialResponse.status;
                //Unauthorized response
                if (status === 401) {
                    console.warn("Token needs refresh");
                    new AsyncStorageHelper().getItem(asyncStorageKeys.tokens,
                        (initialStorageData) => {
                            this.refreshToken(initialResponse, initialStorageData, callbackSuccess, callbackFailure, method, status);
                        })
                } else {
                    //Other status codes are handled in each request callback
                    //hacky way to by pass the empty response
                    let empty_data = null;
                    if (initialResponse.status === 204) {
                        callbackSuccess(empty_data, status)
                    } else {
                        callbackFailure(empty_data, status)
                    }
                    // initialResponse.json().then(data => {
                    //     callbackSuccess(data, status);
                    // });
                }

            })
            .catch((error) => {
                //General callback for all errors
                callbackFailure(error);
            });
        return this;
    };

    refreshToken = (initialResponse, initialStorageData, callbackSuccess, callbackFailure, method, status) => {
        fetch(urlRefreshToken, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh: initialStorageData.refresh
            }),
        })
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    response.json().then((jsonResponse) => {
                        //Update the tokens in memory (Redux)
                        if (this.refreshMemoryTokens !== null) {
                            let tokens = {
                                access: jsonResponse.access,
                                refresh: jsonResponse.refresh,
                            };
                            console.log("Executing the function...");
                            this.refreshMemoryTokens(tokens);
                        }

                        autoAuthenticate({
                            access: jsonResponse.access,
                            refresh: jsonResponse.refresh,
                            history: this.history
                        });
                        //Update the token in the local instance
                        this.token = jsonResponse.access;
                        //reconstruct the headers to take the new token
                        this.constructHeaders();
                        //Both Get and Delete method doesn't have body at all
                        if (method === "POST" || method === "PUT")
                            this.body.token = jsonResponse.access;
                        // if(method !== "DELETE")
                        //     this.body.token = jsonResponse.access;

                        //Update the storage with the new tokens
                        let dataToStorage = {
                            "access": jsonResponse.access,
                            "refresh": jsonResponse.refresh,
                        };
                        //TODO:
                        new AsyncStorageHelper().setItem(asyncStorageKeys.tokens, dataToStorage);
                        //Rerun the request with the updated token
                        if (method === "POST") {
                            this.postMethod(this.body, (data, status) => {
                                console.warn("inside postmethod callback");
                                //Pass the status code as 200 to force rerun the request (Hacky way)
                                callbackSuccess(data, status);
                            })
                        } else if (method === "PUT") {
                            this.putMethod(this.body, (data, status) => {
                                console.warn("inside putMethod callback");
                                //Pass the status code as 200 to force rerun the request (Hacky way)
                                callbackSuccess(data, status);
                            });
                        } else if (method === "GET") {
                            this.getMethod((data, status) => {
                                console.warn("inside getMethod callback");
                                //Pass the status code as 200 to force rerun the request (Hacky way)
                                callbackSuccess(data, status);
                            });
                        } else if (method === "DELETE") {
                            this.deleteMethod((data, status) => {
                                console.warn("inside deleteMethod callback");
                                //Pass the status code as 200 to force rerun the request (Hacky way)
                                callbackSuccess(data, status);
                            });
                        }
                    });
                } else {
                    //TODO: Refresh the "refresh token" which is updated once a week
                    // NavigationService.navigate(LOGOUT_SCREEN);
                    // this.history.push("/home")
                }
            })
            .catch((error) => {
                callbackFailure(error);
            })


    }
}

export default UrlRequest;
