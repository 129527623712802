/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container, Row, Col,
} from "reactstrap";

import routes from "../../dashboardRoutes.js";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {LANGUAGES} from "../../settings";
import {setLanguage} from "../../configuration/redux/actions/ui";
import PermissionFilter from '../../components/PermissionFilter/PermissionFilter';

import './DashboardNavBar.scss';
import {NavLink} from "react-router-dom";
import NavLinkWithPermission from "../NavLinkWithPermission/NavLinkWithPermission";

class DashboardNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownOpen: false,
            dropDownLanguageOpen: false,
            color: "transparent"
        };
        this.toggle = this.toggle.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.dropdownLanguageToggle = this.dropdownLanguageToggle.bind(this);
        this.sidebarToggle = React.createRef();
    }

    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: ""
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    dropdownToggle(e) {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    dropdownLanguageToggle(e) {
        this.setState({
            dropDownLanguageOpen: !this.state.dropDownLanguageOpen
        });
    }

    getBrand() {
        let brandName = "Default Brand";
        routes.map((prop, key) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                brandName = prop.name;
            }
            return null;
        });
        return brandName;
    }

    openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        this.sidebarToggle.current.classList.toggle("toggled");
    }

    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: ""
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.sidebarToggle.current.classList.toggle("toggled");
        }
    }

    navigateTo(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                expand="lg"
                color={this.state.color}
                className={[
                    this.props.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute fixed-top"
                        : "navbar-absolute fixed-top " +
                        (this.state.color === "transparent" ? "navbar-transparent " : "navbar-color")
                    , this.state.isOpen ? "pt-2" : ""]}>
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref={this.sidebarToggle}
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1"/>
                                <span className="navbar-toggler-bar bar2"/>
                                <span className="navbar-toggler-bar bar3"/>
                            </button>
                        </div>
                        <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
                    </div>
                    <NavbarToggler onClick={this.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                    </NavbarToggler>
                    <Collapse
                        isOpen={this.state.isOpen}
                        navbar
                        className="justify-content-end"
                    >
                        <Nav navbar>
                            <Dropdown
                                nav
                                isOpen={this.state.dropDownLanguageOpen}
                                toggle={e => this.dropdownLanguageToggle(e)}>
                                <DropdownToggle caret nav>
                                    <i className="fas fa-globe-americas"/>
                                    <p>
                                        <span className="d-lg-none d-md-block">Languages</span>
                                    </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {LANGUAGES.map((language) => {
                                        return (
                                            <DropdownItem tag="a" onClick={() => this.props.setLanguage(language.code)}>
                                                {language.prettier}
                                            </DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown
                                nav
                                isOpen={this.state.dropdownOpen}
                                toggle={e => this.dropdownToggle(e)}>
                                <DropdownToggle caret nav>
                                    <i className="nc-icon nc-settings-gear-65"/>
                                    <p>
                                        <span className="d-lg-none d-md-block">Settings</span>
                                    </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <PermissionFilter
                                        permissions_required={["user.view_profile"]}>
                                        <DropdownItem tag="a"
                                                      onClick={() => this.props.history.push("/profile")}>Profile</DropdownItem>
                                    </PermissionFilter>
                                    <DropdownItem tag="a"
                                                  onClick={() => this.navigateTo("/logout")}>
                                        Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <PermissionFilter permissions_required={["cart.view_cart"]}>
                                <NavLink
                                    exact
                                    to="/cart"
                                    className="nav-link"
                                    activeClassName="active">
                                    <i className="nc-icon nc-cart-simple">
                                        {this.props.badge > 0 ?
                                            <div className="number-dashboard-circle">
                                                {this.props.badge}
                                            </div>
                                            : null
                                        }
                                    </i>
                                </NavLink>
                            </PermissionFilter>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuthenticated: state.authentication.isAuthenticated,
        badge: state.cart.badge,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (language) => dispatch(setLanguage(language)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardNavbar));
