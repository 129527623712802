import * as actionTypes from '../actions/actionTypes';
import updateObject from "../../../../../utils/updateObject";

const initialState = {
    herbs: [],
    products: [],
    final_products: [],
    badge: 0,
    cart_id: 0,
};

const resetCart = (state, action) => {
    return updateObject(state, initialState);
};

const addHerbItem = (state, action) => {
    return {
        ...state,
        herbs: state.herbs.concat(action.herb)
    }
};

const incrementTotalBadge = (state, action) => {
    return {
        ...state,
        badge: state.badge + 1
    }
};

const decrementTotalBadge = (state, action) => {
    let badge = state.badge;
    return {
        ...state,
        badge: badge > 0 ? state.badge - 1 : badge
    }
};

const setTotalBadge = (state, action) => {
    return {
        ...state,
        badge: action.badge,
    }
};

const setCartId = (state, action) => {
    return {
        ...state,
        cart_id: action.cart_id,
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_HERB_ITEM_SUCCESS:
            return addHerbItem(state, action);
        case actionTypes.SET_TOTAL_BADGE:
            return setTotalBadge(state, action);
        case actionTypes.INCREMENT_CART_BADGE:
            return incrementTotalBadge(state, action);
        case actionTypes.DECREMENT_CART_BADGE:
            return decrementTotalBadge(state, action);
        case actionTypes.CART_ID_ACTION:
            return setCartId(state, action);
        case actionTypes.RESET_CART:
            return resetCart(state, action);
        default:
            return state;
    }
};

export default reducer;
