//Authentication types
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
//Remember me
export const REMEMBER_ME = 'REMEMBER_ME';
//Token types
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';
export const SET_AUTH_TRUE = 'SET_AUTH_TRUE';

export const RESET_AUTH = 'RESET_AUTH';

export const SET_UPDATED_TOKENS = "SET_UPDATED_TOKENS";
