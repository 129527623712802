/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router} from "react-router-dom";
//Material Ui Configuration
import {ThemeProvider} from '@material-ui/core';
import {createMuiTheme} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

//Custom theming for reacstrap
import './assets/custom_reactstrap.scss';

//React wysiwyg editor css file
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//React Image scss
import './assets/custom_image_gallery.scss';

//Slider css
import 'react-input-range/lib/css/index.css';

//React Input Numbers scss
import './assets/react-input-number-custom.scss';

//Global App Styles
import './App.scss';


//Redux configuration
import {createStore, applyMiddleware, compose} from "redux";
import allReducers from "./configuration/redux/configuration";
import {Provider} from "react-redux";
import thunk from 'redux-thunk';

import App from "./App";

// console.log = function(){};

const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const myStore = createStore(
    allReducers,
    composeEnhancers(
        applyMiddleware(
            thunk
        )
    )
);

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#1E5631',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#000000',
            main: '#000000',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#fff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});


ReactDOM.render(
    <Provider store={myStore}>
        <ThemeProvider theme={theme}>
            <Router history={hist} basename={"/"}>
                <App/>
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);
