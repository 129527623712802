/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link, NavLink, withRouter} from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Input, Row
} from "reactstrap";

import routes from "../../rootRoutes.js";
import rootRoutes from "../../rootRoutes";
import {connect} from "react-redux";
import {setLanguage} from "../../configuration/redux/actions/ui";
import {LANGUAGES} from "../../settings";
import selectLanguage from "../../utils/languageSelector";
// import './DashboardNavBar.scss';
import './RootNavBar.scss';
import PermissionFilter from "../PermissionFilter/PermissionFilter";

class RootNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownOpen: false,
            color: "transparent"
        };
        this.toggle = this.toggle.bind(this);
        this.dropdownToggle = this.dropdownToggle.bind(this);
        this.sidebarToggle = React.createRef();
    }

    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: "dark"
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    dropdownToggle(e) {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    getBrand() {
        let brandName = "BioActiveHerb";
        rootRoutes.map((prop, key) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                if (this.props.language === "el")
                    brandName = prop.name_el;
                else
                    brandName = prop.name_en;
            }
            return null;
        });
        return brandName;
    }

    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "dark"
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
    }

    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.sidebarToggle.current.classList.toggle("toggled");
        }
    }

    render() {
        return (
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                expand="lg"
                className={[
                    this.props.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute fixed-top "
                        : "navbar-absolute fixed-top " +
                        (this.state.color === "transparent" ? " " : ""),
                    "navbar"
                ]}
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <NavbarBrand href="">{this.getBrand()}</NavbarBrand>
                    </div>
                    <NavbarToggler onClick={this.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                    </NavbarToggler>
                    <Collapse
                        isOpen={this.state.isOpen}
                        navbar
                        className="justify-content-end">
                        {this.props.routes.map((prop, key) => {
                            if (prop.show) {
                                return (
                                    <NavLink
                                        to={prop.layout + prop.path}
                                        className="nav-link root-navlink"
                                        activeClassName="navbar-active">
                                        <p className="">{this.props.language === "el" ? prop.name_el : prop.name_en}</p>
                                        <i className={prop.icon}/>
                                    </NavLink>
                                );
                            }
                        })}
                        <NavLink
                            exact
                            to="/cart"
                            className="nav-link root-navlink"
                            activeClassName="navbar-active">
                            <Row className="m-1">
                                <i className="nc-icon nc-cart-simple"/>
                                {this.props.badge > 0 ?
                                    <div className="number-root-circle">
                                        {this.props.badge}
                                    </div>
                                    : null
                                }
                            </Row>
                        </NavLink>
                        <Nav navbar>
                            <Dropdown
                                nav
                                isOpen={this.state.dropdownOpen}
                                toggle={e => this.dropdownToggle(e)}>
                                <DropdownToggle caret nav>
                                    <i className="fas fa-globe-americas"/>
                                    <p>
                                        <span className="d-lg-none d-md-block">Language</span>
                                    </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {LANGUAGES.map((language) => {
                                        return (
                                            <DropdownItem tag="a" onClick={() => this.props.setLanguage(language.code)}>
                                                {language.prettier}
                                            </DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.ui.isLoading,
        language: state.ui.language,
        badge: state.cart.badge,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (language) => dispatch(setLanguage(language)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RootNavBar));
